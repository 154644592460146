/* You can add global styles to this file, and also import other style files */
@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Lato|Roboto:400,500');


body{
    font-size: 16px;
	font-family: Verdana, Geneva, sans-serif;
    line-height: 2;
    margin-bottom:150px;
}

h1 { /* making h1 elements blue */
    color: #1b64a3;
}

p, ol, ul, blockquote{ /* a little more whitespace between paragraphs etc */
    margin-top: 1em;
    margin-bottom: 1em;
}

#jumbolink { /* removes underline from the link in jumbotron */
    text-decoration:none;
}

.jumbotron { 
    background-color: #267bc5;
    color: #fff;
    font-family: Roboto, sans-serif;
    padding-bottom:0px;
    margin:0;
    text-decoration:none;
}
.jumbotron p { /* styling for the p element within the jumbotron (i.e., the subtitle of site) */
    margin-top: 0em;
    margin-bottom: 0em;
    font-weight:100;
    position: relative;
}
@media screen and (max-width: 768px) {
	.jumbotron p {
		font-size:15px;
  }
}
.jumbotron h1{
    position: relative;
}
#page-sub-header{
    padding-top:0;
    padding-right:0;
    padding-bottom:1rem;
    padding-left: 0;
}

label {
    margin-right:5px;
}


.navbar {
    background-color: #267bc5;
    z-index: 999999;
    border: 0;
    font-size: 80%;
    font-family: Roboto, sans-serif;
    line-height: 1.42857143;
    border-radius: 0;
    padding-bottom:0;
}

#main-navigation li a {
    color: #fff;
    letter-spacing: 4px;
    padding-top:0;
    padding-bottom:0;
    line-height: 4rem;
}

#main-navigation li a:hover, #main-navigation li.active a {
    color: #267bc5;
    background-color: #fff;
}

#main-navigation .btn-primary{
    border:0;
    letter-spacing: 4px;
    padding-top:0;
    padding-bottom:0;
    line-height: 4rem;
    background-color: #267bc5;
}

#main-navigation .btn-primary:hover{
    color: #267bc5;
    background-color: #fff;
}

#main-navigation .dropdown-menu li a{
    color:#267bc5;
    background-color:#fff
}

#main-navigation .dropdown-menu li a:hover {
    color: #fff;
    background-color: #267bc5;
}

/* This is for the "ARTICLES" link in navigation */
.nav>.btn-group>a{
    display:inline-block;
    padding-right:0;
}

/* This is for the caret on the "ARTICLES" link in navigation */
.btn-group>.btn{
    float:none;
    padding-left:5px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #267bc5;
    color: white;
    text-align: center;
    font-size:14px;
}

.footer a {
    color: white;
    text-decoration:none;
}

.alert {
padding: 0.65rem 15px 0.65rem 15px;
}

#contentarea {
    margin-bottom:100px;
}

.container{
    max-width: 1080px;
}

@media only screen and (max-width: 600px) {
    .footer {
        position:static;
    }
}

@media print {
    .no-print{
      display:none;
    }
    .inputexplanation{
        display:none;
    }
  }


/* Hide the range component if somebody is using "screen" media (i.e., computer screens, tablets, smart-phones etc) and that screen is 710px or less */  
@media only screen and (max-width: 710px) {
    #range{
        display:none;
    }
}

/* Hide the range component if somebody is using "speech" media (i.e., screenreader), because the component only works in a visual context */  
@media only speech {
    #range{
        display:none;
    }
}
